<template>
  <div class="combo-sheet-container">
    <van-action-sheet :value="comboShow"
                      round duration="0.4"
                      @click-overlay="$emit('changeComboShow',false)">
      <div class="combo-group" v-show="combosOfAllred.length && device.deviceType !== '1'">
        <van-sidebar v-model="activeKey" class="sidebar-box">
          <van-sidebar-item title="满堂红"/>
        </van-sidebar>
        <div class="combo-box">
          <div class="item" v-for="(item,index) in combosOfAllred" :key="item.comboId"
               :class="{ 'border-red': comboId === item.comboId }"
               @click="selectComboFn(item)"
          >
            <svg-icon icon-class="meal-h" className="svg-meal"/>
            <div class="time"><i class="iconfont icon-clock"></i>{{getMinutes(item.comboTimes)}}</div>
            <div class="money">￥{{item.comboMoney}}元</div>
            <div class="name">满堂红</div>
          </div>
        </div>
      </div>
      <div class="combo-group" v-show="comboType.length">
        <van-sidebar v-model="activeKey" class="sidebar-box">
          <van-sidebar-item :title="device.deviceType === '3' || device.deviceType === '1'? '单对':'单个'"/>
        </van-sidebar>
        <div class="combo-box">
          <div class="item" v-for="(item,index) in comboType" :key="item.comboId"
               :class="{ 'border-red': comboId === item.comboId }"
               @click="selectComboFn(item)"
          >
            <svg-icon icon-class="meal-h" className="svg-meal"/>
            <div class="time"><i class="iconfont icon-clock"></i> {{getMinutes(item.comboTimes)}}</div>
            <div class="money">￥{{ item.comboMoney }}元/{{device.deviceType === '3' || device.deviceType === '1'? '对':'个'}}</div>
<!--            <div class="name">{{device.deviceType === '3'? '单对':'单个'}}</div>-->
          </div>
        </div>
      </div>
      <div class="col-flex">
        <div class="confirm" @click="confirmLight">确定</div>
        <div class="cancel" @click="cancelLight">取消</div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'
import { mapGetters, mapMutations, mapState } from 'vuex'
import getMinutes from '@/utils/getMinutes'
import device from '@/store/modules/device'

export default {
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    comboShow:{
      type: Boolean,
      required: true,
    }
  },
  // 这里用于存放数据
  data() {
    return {
      activeKey: 0,
      isShow: false,
    }
  },
//  计算属性 类似于data概念
  computed: {
    ...mapGetters('combo',['combosOfType','combosOfAllred','getComboById']),
    ...mapState('device',['device']),

    comboType(){
      return this.combosOfType(this.device.deviceType)
    },
    comboId(){
      return this.$store.state.comboId
    },
  },
//  监控data 中数据变化
  watch: {
    comboType: {
      immediate: true,
      handler(newVal) {
        console.log(newVal)
        if (newVal.length > 0) {
          this.selectComboFn(newVal[0]);
          this.confirmLight();
        }else{
          //获取满堂红套餐
          this.selectComboFn(this.combosOfAllred[0])
          this.confirmLight();
        }
      },
    },
    comboId:{
      immediate: true,
      handler(newVal,oldVal){
        if(newVal && oldVal){
          //console.log(newVal,oldVal)
          const newCombo = this.getComboById(newVal)
          const oldCombo = this.getComboById(oldVal)
          if(newCombo && oldCombo && newCombo.comboType !== oldCombo.comboType){
            if(newCombo.comboType !== '0'){
              this.setRedAll(false)
              this.setLightingArr([])
            }
          }
        }

      }
    }
  },
//  方法集合
  methods: {
    ...mapMutations(['setRedAll','setComContent','setLightingArr']),
    getMinutes,
    selectComboFn(item){
      if(item){
        this.$store.state.comboId = item.comboId
      }
    },
    confirmLight(){
      //选套餐给父类
      let content = ''
      const combo = this.getComboById(this.comboId)
      if (!combo) {
        /*console.error(`No combo found for comboId`);*/
        return; // 或者处理这个错误，例如显示一个用户友好的消息
      }
      if(combo.comboType === '0'){
        content = '满堂红'
        this.setRedAll(true)
        //this.$store.commit('setRedAll',true)
      }else if(combo.comboType === '3' || combo.comboType === '1'){
        content = '单对'
        this.setRedAll(false)
        //this.$store.commit('setRedAll',false)
      }else{
        content = '单个'
        this.setRedAll(false)
        //this.$store.commit('setRedAll',false)
      }
      this.setComContent(content + ' ' + getMinutes(combo.comboTimes))
      //this.$store.state.comboContent = content + ' ' + getMinutes(combo.comboTimes)

      //关闭弹窗
      this.$emit('changeComboShow',false)
    },
    cancelLight(){

      this.$emit('changeComboShow',false)
    },


  },
//  生命周期 -创建完成（可以访问当前this 实例）
  created() {

  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted() {
    // console.log(33333333333333)
    // this.$nextTick(() => {
    //   if (this.comboType.length > 0) {
    //     this.selectComboFn(this.comboType[0]);
    //     this.confirmLight();
    //   }
    // });
  },
//  生命周期 - 创建之前
  beforeCreate() {
  },
//  生命周期 - 挂载之前
  beforeMount() {
  },
//  生命周期 - 更新之前
  beforeUpdate() {

  },
//  生命周期 - 更新之后
  updated() {
  },
//  生命周期 - 销毁之前
  beforeDestroy() {
  },
//  生命周期 - 销毁完成
  destroyed() {
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated() {
  }

}
</script>

<style lang="less" scoped>
:deep .van-action-sheet {
  max-height: 100vh;
}

.combo-sheet-container{
  .combo-group {
    margin: 10px 15px 10px;
    .combo-box{
      display: flex;
      width: calc(375px - 30px);
      flex: 1;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch; /* 解决ios滑动不流畅问题 */
      .item{
        margin: 5px 3px;
        width: 100px;
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        //background-color: #FBF1EE;
        background-color: #f5e5e0;
        .svg-icon {
          width: 76px;
          height: 60px;
        }
        .time {
          display: flex;
          align-items: center;
          height: 20px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC,sans-serif;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 20px;
          text-align: center;
          i{}
        }
        .money {
          font-weight: 600;
          font-size: 16px;
        }
        .name {
          font-size: 14px;
          color: rgba(0, 0, 0, .85);
        }
      }
      .border-red {
        border: 2px solid #b34341;
        border-radius: 5px;
        box-sizing: border-box;
      }
    }
  }
}

.col-flex{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  margin: 5px 0 10px;
  .confirm{
    width: 345px;
    height: 40px;
    line-height: 42px;
    background: #A92B2A;
    border-radius: 21px;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC,sans-serif;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }
  .cancel{
    width: 345px;
    height: 42px;
    border-radius: 21px;
    border: 1px solid #e0e6e9;
    text-align: center;
    line-height: 42px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC,sans-serif;
    font-weight: 500;
    color: #999999;
    letter-spacing: 1px;
  }

}
</style>
